import {
  BookingsRolloutManagerApp,
  GetSiteRolloutStatusResponse,
} from '@wix/ambassador-bookings-rollout-manager-app/http';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';

export const isEcomSite = (wixSdkAdapter: WixOOISDKAdapter) : Promise<boolean> => {
  return BookingsRolloutManagerApp('/_api/bookings-rollout-manager-app')
    .BookingsRolloutManager()({ Authorization: wixSdkAdapter.getInstance() })
    .getSiteRolloutStatusForV1Shutdown({})
    .then((response: GetSiteRolloutStatusResponse) => {
      return Boolean(response.siteRolloutStatus?.isBookingPlatformReady);
    })
    .catch(() => true);
};
